.birth {
  &-form{
    margin-bottom: 32px;
  }
  &-main {
    margin-top: 36px;
    margin-bottom: 32px;

    @include breakpoint(medium down) {
      margin-top: 30px;
    }
    @include breakpoint(small down) {
      margin-top: 20px;
    }
  }
  &-box {
    display: flex;
    margin-bottom: 56px;
    justify-content: space-between;
    @include breakpoint(medium down) {
      justify-content: center;
      margin-bottom: 50px;
      img {
        display: none;
      }
    }
    @include breakpoint(small down) {
      margin-bottom: 35px;
    }
  }
  &-text {
    h1 {
      color: var(--primary-dark-color);
      margin-bottom: 16px;
    }
    max-width: 690px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    @include breakpoint(medium down) {
      font-size: 20px;
    }
    @include breakpoint(small down) {
      font-size: 14px;
    }
  }
  &-certificates {
    max-width: 1057px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 207px);
    justify-content: space-between;
    gap: 32px;
    padding: 32px;
    background-color: var(--white);
    border-radius: 12px;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.07);
    @include breakpoint(medium down) {
      grid-template-columns: repeat(3, 207px);
      gap: 22px;
      background-color: transparent;
      box-shadow: none;
      padding: 0 30px;
    }
    @include breakpoint(small down) {
      grid-template-columns: repeat(1, 100%);
      gap: 16px;
    }
  }
  &-card {
    border-radius: var(--radius);
    overflow: hidden;
    &-head {
      border-bottom: 3px solid var(--black);
      display: flex;
      img {
        object-fit: cover;
        width: 100%;
        max-height: 110px;
      }
    }
    &-foot {
      background: var(--primary-color);
      color: var(--white);
      padding: 12px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 10px;
      font-size: 14px;
      font-weight: 600;
    }
    .button {
      font-family: var(--title-font-family);
      padding: 16px 5px;
      font-weight: 800;
      width: 100%;
      max-width: 258px;
      margin: 0 auto;
    }
    a {
      cursor: pointer;
    }
  }
  &-form {
    @include breakpoint(small down) {
      background-color: var(--primary-dark-color);
    }
    .present-form {
      @include breakpoint(medium down) {
        padding: 32px 10px;
      }
      @include breakpoint(small down) {
        padding: 24px 21px;
      }
    }
  }
  &-donate {
    background-color: var(--white);
    padding: 56px;
    text-align: center;
    @include breakpoint(medium down) {
      padding: 24px;
      margin-bottom: 0;
    }
    .text-img {
      gap: 90px;
      align-items: center;
      @include breakpoint(medium down) {
        flex-direction: row;
        gap: 50px;
      }
      @include breakpoint(small down) {
        flex-direction: column;
        gap: 24px;
      }
    }
    .text-img-title {
      max-width: 460px;
      margin: 16px auto;
      color: var(--secondary-color);
    }
    .text-img-img {
      background-color: transparent;
      padding: 0;
      @include breakpoint(medium down) {
        min-width: 295px;
      }
      @include breakpoint(small down) {
        min-width: 125px;
      }
    }
    label {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    input {
      &::placeholder {
        color: var(--medium-grey);
      }
    }
    .button {
      font-family: var(--title-font-family);
      padding: 16px 5px;
      font-weight: 800;
      width: 207px;
      height: 48px;
      margin: 0 auto;
      display: flex;
      @include breakpoint(small down) {
        width: 100%;
      }
    }
  }

  &-img{
    width: 130px;
    height: 125px;
  }

  &-certificates.new-c{
    max-width: 664px;
    margin: 0 auto;
    border-radius: 12px;
    background: var(--white);
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.07);
    padding: 32px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;

    @include breakpoint(small down) {
      padding: 16px;
      grid-template-columns: repeat(1, 100%);
      gap: 16px;
    }

    .birth-card-head{
      height: 122px;
      width: 100%;
      border-radius: 12px 12px 0 0;
      overflow: hidden;

      @include breakpoint(small down) {
        height: 123px;
      }

      img{
        max-height: 100%;
      }
    }

    .birth-card-title{
      display: flex;
      gap: 4px;
    }

    .get-present-popup-info{
      .birth-card-head{
        height: 110px;
        width: 204px;

        img{
          max-height: 110px;
        }
      }
      .birth-card-title{
        display: block;
      }
    }
  }
}
.donate-card{
  .birth-card-title{
    width: 100%;
  }
}