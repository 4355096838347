.postcards-page {
  .container {
    overflow: hidden;
  }
  &-area {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &-title {
    @include breakpoint(small down) {
      margin-bottom: 2px;
    }
  }

  &-prev,
  &-next {
    background-color: #683fd2;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    cursor: pointer;
    z-index: 5;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateY(-50%);

    @include breakpoint(medium down) {
      width: 39px;
      height: 39px;
      background-size: 17px;
    }

    &.swiper-button-disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  &-prev {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.85705 18.935L0.493087 12.5711C0.102563 12.1805 0.102563 11.5474 0.493086 11.1568L6.85705 4.79288C7.24757 4.40235 7.88074 4.40235 8.27126 4.79288C8.66178 5.1834 8.66178 5.81657 8.27126 6.20709L3.61441 10.8639L22.7002 10.8639C23.2525 10.8639 23.7002 11.3117 23.7002 11.8639C23.7002 12.4162 23.2525 12.8639 22.7002 12.8639L3.61441 12.8639L8.27126 17.5208C8.66179 17.9113 8.66179 18.5445 8.27126 18.935C7.88074 19.3255 7.24757 19.3255 6.85705 18.935Z' fill='white'/%3E%3C/svg%3E");
    left: 0;
  }

  &-next {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.143 18.935L23.5069 12.5711C23.8974 12.1805 23.8974 11.5474 23.5069 11.1568L17.143 4.79288C16.7524 4.40235 16.1193 4.40235 15.7287 4.79288C15.3382 5.1834 15.3382 5.81657 15.7287 6.20709L20.3856 10.8639L1.29981 10.8639C0.747521 10.8639 0.299806 11.3117 0.299806 11.8639C0.299806 12.4162 0.747521 12.8639 1.29981 12.8639L20.3856 12.8639L15.7287 17.5208C15.3382 17.9113 15.3382 18.5445 15.7287 18.935C16.1193 19.3255 16.7524 19.3255 17.143 18.935Z' fill='white'/%3E%3C/svg%3E");
    right: 0;
  }

  .swiper {
    overflow: visible !important;
    width: 72%;
    margin: 0;

    @include breakpoint(medium down) {
      width: 62%;
    }
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 865/615;

    &-inner {
      border-radius: 20px;
      overflow: hidden;
    }

    img {
      height: 100%;
      width: auto;
      max-width: 100%;
      border-radius: 20px;

      @include breakpoint(medium down) {
        max-height: 315px;
        object-fit: contain;
      }

      @include breakpoint(small down) {
        max-height: 213px;
        object-fit: contain;
      }
    }
  }

  .swiper-slide:not(.swiper-slide-active) {
    .swiper-slide-inner {
      background-color: var(--surface-2);
    }
    img {
      opacity: 0.6;
    }
  }
}
