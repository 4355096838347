body {
  display: flex;
  flex-direction: column;
}

.section {
  margin-top: var(--section-space);
  margin-bottom: var(--section-space);

  &.inner {
    padding-top: var(--section-space);
    padding-bottom: var(--section-space);
  }

  &.no-out-space {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.no-top-space {
    margin-top: 0;
  }

  &.no-btm-space {
    margin-bottom: 0;
  }
}

.container {
  max-width: var(--container-width);
  padding-left: var(--container-space);
  padding-right: var(--container-space);
  margin-left: auto;
  margin-right: auto;
  &.small {
    max-width: 1034px;
    @include breakpoint(medium down) {
      max-width: 593px;
    }
    //@include breakpoint(small down) {
    //  max-width: 360px;
    //}
  }
}

.container,
.content {
  @include no-first-last-margin;
}
