[data-select] {
  display: none;
}

.js-select {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  margin-bottom: 1em;

  @include breakpoint(medium) {
    font-size: 14px;
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    width: 100%;
    padding: 8px 12px;
    background: var(--white);
    color: var(--black);
    border-radius: var(--radius-small);
    box-shadow: var(--shadow);
    position: relative;

    .leaders-page & {
      font-size: 16px;
      font-weight: 700;
      min-width: 202px;
      background: var(--primary-lighter-color);
      padding: 6px 16px;
      gap: 16px;

      @include breakpoint(small down) {
        font-size: 12px;
        min-width: 155px;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 11px;
      height: 7px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='7' fill='none'%3E%3Cpath fill='%23333' d='M4.767 6.21.56 1.68C-.034 1.04.42 0 1.293 0h8.414c.873 0 1.327 1.04.733 1.68L6.233 6.21a1 1 0 0 1-1.466 0Z'/%3E%3C/svg%3E")
        no-repeat center center / contain;
      transition: all var(--speed);
    }

    &.is-active:after {
      transform: rotate3d(1, 0, 0, 180deg);
    }
  }

  &-cont {
    position: absolute;
    background: var(--white);
    padding: 6px 0;
    border-radius: var(--radius-small);
    box-shadow: var(--shadow);
    z-index: 99;
    max-height: 250px;
    overflow: auto;
    font-size: 12px;
    line-height: 18px;
    text-align: left;

    @include breakpoint(medium) {
      font-size: 14px;
    }
  }

  &-option {
    display: block;
    width: 100%;
    padding: 8px 16px;

    &:hover {
      color: var(--primary-color);
    }
  }

  &-search {
    width: calc(100% - 32px);
    padding: 8px 0;
    margin: 0 0 8px 16px;
    border-radius: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid var(--primary-light-color);

    &:focus {
      box-shadow: none;
    }
  }
}
