.notifications-page {
  margin-top: 32px;
  @include breakpoint(medium down) {
    margin-top: 24px;
  }
  &-area {
    background: var(--white);
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
    border-radius: var(--radius);
    padding: 40px;
    max-width: 1074px;
    margin: 0 auto 24px;
    @include breakpoint(medium down) {
      padding: 24px 40px;
      margin-bottom: 24px;
    }
    @include breakpoint(small down) {
      padding: 24px 16px;
    }
  }
  &-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &-item {
    display: flex;
    gap: 10px;
    padding: 8px 0 16px 0;
    border-bottom: 1px solid var(--primary-lighter-color);
    @include breakpoint(small down) {
      font-size: 14px;
    }
    &.is-unread {
      font-weight: 700;
    }
    &:last-child {
      border: none;
    }
    strong {
      color: var(--primary-color);
    }
    .button {
      font-family: var(--title-font-family);
      display: flex;
      width: 187px;
      height: 42px;
      margin-top: 10px;
      padding: 16px 10px;
      font-weight: 800;
      font-size: 16px;
      @include breakpoint(small down) {
        width: 100%;
      }
    }
  }
  &-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 35px;
    @include breakpoint(medium down) {
      flex-direction: column;
      justify-content: center;
      gap: 10px;
    }
  }
  &-imgs {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    img {
      min-width: 48%;
      &.small {
        min-width: auto;
      }
      @include breakpoint(medium down) {
        width: 100%;
        &.small {
          width: auto;
        }
      }
    }
    @include breakpoint(small down) {
      flex-direction: column;
      gap: 10px;
    }
  }
  &-date {
    color: var(--medium-grey);
    display: flex;
    gap: 20px;
    margin-left: auto;
    font-weight: 400;
    min-width: 159px;
    white-space: nowrap;
    @include breakpoint(medium down) {
      font-size: 14px;
      margin-left: 0;
    }
  }
  &-icon {
    position: relative;
    min-width: 24px;
    height: 24px;
    .is-unread & {
      position: relative;
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: var(--primary-color);
      }
    }
  }
}
