.how {
  &-img {
    border-radius: 50%;
    width: 115px;
    height: 115px;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
    margin: 0 auto 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    @include breakpoint(medium down) {
      width: 95px;
      height: 95px;
    }
    @include breakpoint(small down) {
      width: 85px;
      height: 85px;
    }
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 1/1;
      margin-top: -11px;
    }
  }
  &-title {
    font-size: 62px;
    font-weight: 900;
    margin-bottom: 28px;
    font-family: var(--title-font-family);
    @include breakpoint(medium down) {
      font-size: 52px;
    }
    @include breakpoint(small down) {
      font-size: 38px;
    }
  }
  &-more {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    margin-top: 56px;
    a {
      text-decoration: underline;
      color: var(--secondary-color);
    }
  }
}
