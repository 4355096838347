// In this file you should wright only base styles for tags!

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: var(--white);
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 10px;
}

html {
  scroll-behavior: smooth;
}

body {
  font: var(--body-font);
  color: var(--body-color);
  background: var(--body-background);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  scroll-behavior: smooth;

  &.surface {
    background: var(--surface);
  }

  &.surface-2 {
    background: var(--surface-2);
  }
}

a {
  color: var(--primary-color);
  text-decoration: underline;
  transition: color var(--speed-fast);

  &:hover {
    color: var(--primary-dark-color);
  }
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 1em 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@include all-headers {
  margin: 0 0 24px;

  @include breakpoint(large) {
    margin-bottom: 32px;
  }

  .content & {
    margin: 24px 0;

    @include breakpoint(large) {
      margin: 32px 0;
    }
  }
}

@for $i from 1 through 6 {
  h#{$i},
  .h#{$i} {
    font: var(--h#{$i}-font);
  }
}

small,
.small-font {
  font-size: var(--small-font-size);
}
