.reveal {
  display: none;
  margin: auto;
  background: var(--white);
  width: 100%;
  max-width: 884px;
  padding: calc(var(--container-space) * 2) var(--container-space);
  border-radius: var(--radius);
  position: relative;

  &-wrap {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 0.7);
    backdrop-filter: blur(10px);
    padding: var(--container-space);
    overflow: auto;
    z-index: 999;

    &.extra-wrap{
      z-index: 1100;
    }

    .reveal {
      display: block;
    }
  }

  &-close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    background: var(--black);
    border-radius: 50%;
    position: absolute;
    top: -12px;
    right: -12px;

    svg {
      fill: var(--white);
      transition: transform var(--speed);
    }

    &:hover {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.product-rev {
  background: var(--surface);

  &-cont {
    display: grid;
    gap: 16px;
    text-align: center;

    @include breakpoint(large) {
      gap: 32px;
    }
  }

  &-ttl {
    font: 700 26px/31px var(--title-font-family);

    @include breakpoint(medium) {
      font: 700 36px/57px var(--title-font-family);
    }
  }

  &-desc {
    font: 700 26px/31px var(--title-font-family);

    :is(img, svg) {
      display: block;
      margin: 0;
    }

    mark {
      display: inline-flex;
      gap: 0.15em;
      align-items: center;
      background: none;
      color: var(--primary-color);

      img {
        margin-top: -5px;
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
  }

  &-form {
    display: grid;
    gap: 16px;
    max-width: 270px;
    margin: 0 auto;

    @include breakpoint(large) {
      gap: 32px;
    }

    &.sending {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &-thx {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 16px;
    font: 700 26px/31px var(--title-font-family);

    @include breakpoint(medium) {
      gap: 32px;
    }

    &-ttl {
      text-transform: uppercase;
    }
  }
}

.comment-reveal {
  &-ttl {
    text-align: center;
    color: var(--secondary-color);
  }

  &-form {
    max-width: 450px;
    margin: auto;

    .button {
      display: flex;
      width: 250px;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.get-present-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;

  h4 {
    font-family: var(--body-font-family);
  }

  &.open {
    display: flex;
  }

  &-wrapper {
    position: relative;
  }

  &-box {
    border-radius: 12px;
    background-color: #f3ebe9;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.07);
    overflow-y: auto;
    max-height: 90vh;
    padding: 24px;
    text-align: center;
    min-width: 80vw;
    max-width: 81vw;
  }

  &-info {
    display: flex;
    justify-content: center;
    width: 204px;
    height: 190px;
    border-radius: 12px;
    overflow: hidden;
    margin: 24px auto;
    flex-direction: column;
  }

  .present-form {
    background-color: transparent;
    padding: 0;

    button.gold {
      margin: 0 auto;
    }

    h4 {
      color: var(--black);
    }
  }

  .reveal-close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    @include breakpoint(small down) {
      width: 32px;
      height: 32px;
      padding: 4px;
    }
  }

  .birth-card-foot {
    .birth-card-btn {
      display: none;
    }

    .birth-card-price {
      text-align: start;
    }
  }
}

.thank-present-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;

  &.open {
    display: flex;
  }

  h2 {
    font-size: 40px;
    @include breakpoint(small down) {
      font-size: 24px;
    }
  }

  &-wrapper {
    position: relative;

    .reveal-close {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      @include breakpoint(small down) {
        width: 32px;
        height: 32px;
        padding: 4px;
      }
    }
  }

  &-box {
    border-radius: 12px;
    background-color: #f3ebe9;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.07);
    overflow-y: auto;
    max-height: 90vh;
    padding: 24px;
    text-align: center;
    min-width: 80vw;
    max-width: 81vw;
  }
}

.zks-card-text-rev {
  background-color: #F3EBE9;

  &-wrap {
    max-width: 576px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    padding-top: 100px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    border-radius: 6px;
    border: 1px solid #2062C5;
    background-color: #FFF;

    @include breakpoint(small down) {
      max-width: 230px;
      padding-top: 40px;
    }
  }

  &-cont {
    background-color: #fff;
    background-position: 0 -2px;
    text-align: center;
    padding-bottom: 5px;
    font: inherit;
    font-size: 18.6px;
  }
}

.zks-card-txt.more-text {
  overflow: hidden;
}

.zks-card-open-text-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, #FFF 80.21%);
  padding-top: 50px;
  color: #683FD2;
  font-family: Sofia Sans;
  font-size: 15px;
  cursor: pointer;
  font-weight: 700;
  pointer-events: visible;
  display: none;

  .more-text & {
    display: block;
  }
}

.sup-btn{
  padding: 6px;
  position: fixed;
  bottom: 122px;
  right: 37px;
  cursor: pointer;
  z-index: 19;

  @include breakpoint(medium down) {
    bottom: 50px;
    right: 30px;
  }

  @include breakpoint(small down) {
    bottom: 20px;
    right: 5px;
  }

  &-button{
    width: 77px;
    height: 77px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #683FD2;
    border: none;
    border-radius: 50%;
    transition: all .3s;
    padding: 0;
    outline: 6px solid #683fd212;
    margin: 0 auto;

    svg{
      width: 37px;
      height: 37px;
      transition: all .3s;
    }

    @include breakpoint(small down) {
      width: 56px;
      height: 56px;

      svg{
        width: 30px;
        height: 30px;
      }
    }
  }

  &:hover{
    button{
      outline: 6px solid transparent;
    }

    svg{
      fill: #fff;
    }
  }
}

.support-reveal {
  background: #FBFAF3;

  .reveal-close{
    @include breakpoint(small down) {
      width: 32px;
      height: 32px;

      svg{
        width: 20px;
        height: 20px;
      }
    }
  }

  &-txt {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    max-width: 647px;
    margin: 16px auto;

    @include breakpoint(small down) {
      font-size: 20px;
    }
  }

  &-form {
    max-width: 647px;
    margin: auto;
    background: #FBFAF3;

    textarea{
      background: transparent;
      @include breakpoint(small down) {
        font-size: 14px;
      }
    }

    .button {
      display: flex;
      width: 233px;
      height: 56px;
      max-width: 100%;
      margin: 0 auto;

      @include breakpoint(small down) {
        width: 100%;
      }
    }
  }
}

.birth-donate-popup{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;

  &.open {
    display: flex;
  }

  &-wrapper {
    position: relative;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.07);
    background: #F3EBE9;
    padding: 24px;
    border-radius: 12px;
    width: 90%;
    max-width: 883px;

    .reveal-close {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      @include breakpoint(small down) {
        width: 32px;
        height: 32px;
        padding: 4px;
      }
    }

    .box{
      max-width: 527px;
      margin: 6% auto;
      text-align: center;
    }

    h3{
      color: #2062C5;
      font-weight: 700;
      margin-bottom: 16px;
    }

    input{
      box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.10);
      padding: 12px 14px;
      height: 48px;
      width: 100%;
      max-width: 380px;

      &::placeholder{
        color: #8392A6;
      }
    }
    span.error{
      width: 100%;
      max-width: 380px;
    }

    button.button{
      padding: 13px 48px;
      min-width: auto;
      margin: 0 auto;
    }
  }

  &-label{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
  }
}