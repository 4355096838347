.shop {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 24px;
  grid-template-areas:
    'head head'
    'cat sort'
    'grid grid';
  gap: 24px 12px;

  @include breakpoint(medium) {
    padding-bottom: 42px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'head head head'
      'cat empty sort'
      'grid grid grid';
    gap: 24px 40px;
  }

  @include breakpoint(large) {
    padding-bottom: 60px;
    grid-template-areas:
      'empty head head sort'
      'cat grid grid grid';
    grid-template-columns: repeat(4, 1fr);
    gap: 32px 40px;
  }

  &-head {
    grid-area: head;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    > * {
      margin: 0;
    }

    a {
      text-decoration: none;
      color: var(--black);
      opacity: 0.25;
      transition: all var(--speed-fast);

      &:hover {
        color: var(--primary-color);
        opacity: 1;
      }
    }
  }

  &-cats {
    grid-area: cat;
  }

  &-sort {
    grid-area: sort;
    align-self: center;
  }

  &-grid {
    grid-area: grid;
    display: grid;
    gap: 16px 40px;

    @include breakpoint(medium) {
      gap: 24px 40px;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.wish {
  &-page {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 42px;

    @include breakpoint(large) {
      gap: 32px;
      padding-bottom: 56px;
    }
  }

  &-grid {
    @extend .shop-grid;

    @include breakpoint(xlarge) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
