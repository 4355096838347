.button {
  display: inline-flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px 24px;
  color: var(--on-primary-color);
  background: var(--primary-color);
  border-radius: var(--radius);
  font-size: 16px;
  text-transform: none;
  font-weight: 600;
  border: 1px solid var(--primary-color);
  transition: all var(--speed-fast);
  line-height: 20px;

  &:not(.hollow, .gold, .secondary, .disabled) {
    box-shadow: var(--primary-shadow);
  }

  &:is(:hover, :focus) {
    border-color: var(--primary-dark-color);
    color: var(--on-primary-color);
    outline: 0;

    &:not(.bordered) {
      background: var(--primary-dark-color);
    }
  }

  &:is(a) {
    text-decoration: none;
  }

  &.round {
    border-radius: 50px;
  }

  &.hollow {
    background: none;
    color: var(--primary-color);

    &:is(:hover, :focus) {
      color: var(--on-primary-color);
    }
  }

  &.secondary {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
    color: var(--on-secondary-color);

    &:not(.hollow, .bordered) {
      box-shadow: var(--secondary-shadow);
    }

    &:is(:hover, :focus) {
      border-color: var(--secondary-dark-color);

      &:not(.bordered) {
        background: var(--secondary-dark-color);
      }
    }

    &.hollow {
      background: none;
      color: var(--secondary-color);

      &:is(:hover, :focus) {
        color: var(--on-secondary-color);
      }
    }
  }

  &.gold {
    background: var(--yellow);
    border-color: var(--yellow);
    color: var(--black);

    &:is(:hover, :focus) {
      border-color: var(--gold);

      &:not(.bordered) {
        background: var(--gold);
      }
    }

    &.hollow {
      background: none;
      color: var(--yellow);

      &:is(:hover, :focus) {
        color: var(--black);
      }
    }
  }

  &:is(.disabled, [disabled]) {
    background: var(--disabled-color);
    border-color: var(--disabled-color);
    color: var(--on-disabled-color);
    cursor: not-allowed;

    &:is(:hover, :focus) {
      background: var(--disabled-color);
      border-color: var(--disabled-color);
    }

    &.hollow {
      background: none;
      color: var(--disabled-color);

      &:is(:hover, :focus) {
        color: var(--disabled-color);
      }
    }
  }

  &.bordered {
    border-color: var(--black);
    box-shadow: var(--black-shadow);

    &:hover {
      border-color: var(--black);
      box-shadow: var(--black-shadow-hover);
      transform: translateY(3px);
    }
  }

  &.expanded {
    display: flex;
    width: 100%;
  }

  &.large {
    font: 800 20px/24px var(--title-font-family);
    padding: 14px 32px;
  }

  &.login-btn {
    font-weight: 400;

    > :is(svg, img) {
      margin: -10px 0;
    }
  }
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 1rem;

  &.expanded {
    .button {
      flex: 1 0 320px;
    }
  }
}
