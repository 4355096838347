.cat-drop {
  position: relative;
  overflow: visible;
  background: var(--yellow);
  border: 1px solid var(--black);
  border-radius: var(--radius-small);
  box-shadow: var(--black-shadow);
  z-index: 12;

  &-wrap {
    position: relative;
    overflow: visible;

    @include breakpoint(medium down) {
      height: 42px;
    }
  }

  &-btn {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    padding: 12px;

    svg {
      fill: var(--black);
      margin-top: -2px;
      transition: all var(--speed);
    }

    &.is-active svg {
      transform: rotate3d(1, 0, 0, 180deg);
    }
  }

  &-list {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0 12px 12px 6px;
    z-index: 999;

    @include breakpoint(large) {
      display: block;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }

    a {
      display: inline-block;
      text-decoration: none;
      color: var(--black);
      font: 700 16px/22px var(--body-font-family);
      padding: 2px 6px;

      &:hover {
        color: var(--primary-color);
      }

      &.is-active {
        background: var(--primary-color);
        color: var(--on-primary-color);
        border-radius: var(--radius-small);
      }
    }
  }
}
