.bread {
  --gap: 5px;
  list-style: none;
  margin: 1em 0;
  padding: 0 0 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--gap);
  color: var(--medium-grey);
  font-size: 12px;
  border-bottom: 1px solid #f0efe8;

  @include breakpoint(medium) {
    font-size: 14px;
  }

  > svg {
    fill: var(--medium-grey);
  }

  a {
    display: flex;
    align-items: center;
    gap: var(--gap);
    text-decoration: none;
    color: var(--medium-grey);
    transition: color var(--speed-fast);

    svg {
      fill: var(--medium-grey);
      transition: fill var(--speed-fast);
    }

    &:hover {
      color: var(--primary-color);

      svg {
        fill: var(--primary-color);
      }
    }
  }

  &-wrap {
    margin-top: 16px;
    margin-bottom: 32px;
  }
}
