.header {
  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 12;
  padding: 12px 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);

  &-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--gap);
    height: 43px;

    @include breakpoint(large down) {
      gap: 10px;
    }
  }

  &-logo {
    display: flex;
    order: 1;
    padding-right: 20px;
    :where(img, svg, picture) {
      display: inline-block;
      height: 40px;
      min-width: 217px;
      @include breakpoint(medium down) {
        min-width: 41px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-right: 0;
      }
    }
  }

  &-nav{
    order: 2;
  }

  &-nav,
  &-lang {
    @include breakpoint(medium down) {
      display: none !important;
    }
  }

  &-act {
    display: flex;
    align-items: center;
    gap: 32px;
    order: 3;
    //margin-left: auto;

    @include breakpoint(medium down) {
      gap: 15px;
    }
    @include breakpoint(small down) {
      gap: 24px;
    }
  }
  &-notifications {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    @include breakpoint(small only) {
      margin-right: 0;
    }
    &.is-empty {
      svg {
        fill: var(--dark-grey);
      }
    }
    .counter {
      position: absolute;
      top: -5px;
      right: -8px;
    }
    svg {
      transition: all var(--speed-fast);
    }
    &:hover {
      svg {
        fill: var(--primary-color);
      }
    }
  }

  &-user {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    &-balance {
      text-align: center;
      @include breakpoint(small down) {
        display: none;
      }
    }
    .label {
      font-size: 12px;
      color: var(--medium-grey);
    }
    .value {
      font-size: 24px;
      line-height: 1;
      font-weight: 700;
      color: var(--primary-dark-color);
    }
    &-avatar {
      overflow: hidden;
      border: 3px solid var(--primary-light-color);
      width: 43px;
      height: 43px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }

  &-toggle {
    @include breakpoint(large) {
      display: none;
    }
    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      width: 42px;
      background: var(--dark-grey);
      border-radius: 5px;
      svg {
        path {
          transition: all var(--speed);
          transform-origin: center;
        }
      }

      &.is-active {
        svg {
          path {
            &:nth-child(1) {
              transform: translate(-4px, 4px) rotate(45deg);
            }

            &:nth-child(2) {
              opacity: 0;
            }

            &:nth-child(3) {
              transform: translate(-4px, -4px) rotate(-45deg);
            }
          }
        }
      }
    }
  }
  .counter {
    width: 15px;
    height: 15px;
    background-color: var(--red);
    color: var(--white);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}
