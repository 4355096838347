.leaders-page {
  margin-top: 20px;

  @include breakpoint(small down) {
    margin-top: -16px;
  }

  &.hero {
    background: transparent;
    padding: 0;

    .leaders-top {
      justify-content: flex-end;
    }
  }

  h1 {
    font-family: Sofia Sans;
    font-size: 40px;
    font-weight: 700;

    @include breakpoint(small down) {
      font-size: 26px;
      margin-bottom: 16px;
    }
  }

  &-area {
    background: var(--white);
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
    border-radius: var(--radius);
    padding: 24px 32px;
    max-width: 1042px;
    margin: 0 auto 24px;
    @include breakpoint(small down) {
      padding: 24px 16px;
    }
  }
}
