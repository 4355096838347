@import './settings';
@import '../utils/index';

:root {
  // Fonts
  --body-font-family: 'Inter', system-ui;
  --body-font: 400 16px/1.4 var(--body-font-family);
  --medium-font-size: 14px;
  --small-font-size: 12px;
  --tiny-font-size: 12px;

  --title-font-family: 'Sofia Sans', system-ui;
  --h1-font: 900 38px/1.3 var(--title-font-family);
  --h2-font: 700 26px/1.3 var(--title-font-family);
  --h3-font: 700 20px/1.3 var(--title-font-family);
  --h4-font: 700 16px/1.3 var(--title-font-family);
  --h5-font: 700 16px/1.3 var(--title-font-family);
  --h6-font: 600 14px/1.3 var(--title-font-family);

  // Palette
  --white: #fff;
  --light-grey: #f2f4f9;
  --medium-grey: #8392a6;
  --dark-grey: #333;
  --black: #000;
  --on-white: var(--black);
  --on-light-grey: var(--black);
  --on-medium-grey: var(--white);
  --on-dark-grey: var(--white);
  --on-black: var(--white);

  --primary-color: #683fd2;
  --primary-dark-color: #503c82;
  --primary-light-color: #dccefe;
  --primary-lighter-color: #efedfc;
  --on-primary-color: var(--white);
  --on-primary-dark-color: var(--white);
  --on-primary-light-color: var(--white);

  --disabled-color: #d0d3d7;
  --on-disabled-color: var(--white);

  --secondary-color: #2062c5;
  --secondary-dark-color: #1550a8;
  --secondary-light-color: #d7e6ff;
  --secondary-lighter-color: #f0f5fd;
  --on-secondary-color: var(--white);
  --on-secondary-dark-color: var(--white);
  --on-secondary-light-color: var(--black);

  --surface: #f3ebe9;
  --surface-2: #fbfaf3;
  --on-surface: var(--black);
  --on-surface-2: var(--black);

  --red: #ff4f32;
  --red-light: #fff5f5;
  --on-red: var(--white);
  --on-red-light: var(--black);

  --green: #42c777;
  --green-light: #f5fff9;
  --on-green: var(--white);
  --on-green-light: var(--black);

  --gold: #ffa800;
  --yellow: #ffeb51;

  --body-color: var(--black);
  --body-background: var(--white);

  // Shadows
  --shadow: 0 2px 14px rgba(0, 0, 0, 0.07);
  --shadow-2: 0px 0px 11px 11px rgba(0, 0, 0, 0.11);
  --primary-shadow: 0 4px 10px rgba(123, 84, 223, 0.23);
  --secondary-shadow: 0 5px 20px rgba(32, 98, 196, 0.23);
  --black-shadow: 0 4px 0 var(--black);
  --black-shadow-hover: 0 0 0 var(--black);
  --inner-sharow: inset -3px 3px 5px rgba(0, 0, 0, 0.21);

  // Radius
  --radius: 12px;
  --radius-small: 6px;

  // Sizes
  --gap: 40px;
  --content-width: #{map-get($content-width, small)}px;
  --container-space: 20px;
  --container-width: calc(var(--content-width) + var(--container-space) * 2);
  --section-space: 40px;

  // Transitions
  --speed: 0.3s;
  --speed-fast: 0.15s;

  // Responsive
  @each $name, $val in $content-width {
    @if $name != 'small' {
      @include breakpoint($name) {
        --content-width: #{$val}px;
      }
    }
  }

  @include breakpoint(medium) {
    --h1-font: 900 52px/1.3 var(--title-font-family);
    --h2-font: 700 36px/1.3 var(--title-font-family);
    --h3-font: 700 28px/1.3 var(--title-font-family);
    --h4-font: 700 24px/1.3 var(--title-font-family);
    --h5-font: 700 20px/1.3 var(--title-font-family);
    --h6-font: 600 18px/1.3 var(--title-font-family);

    --section-space: 56px;
  }

  @include breakpoint(xlarge) {
    --h1-font: 900 62px/1.3 var(--title-font-family);
    --h2-font: 700 40px/1.3 var(--title-font-family);
    --h3-font: 700 32px/1.3 var(--title-font-family);
    --h4-font: 700 24px/1.3 var(--title-font-family);
    --h5-font: 700 18px/1.3 var(--title-font-family);
    --h6-font: 600 16px/1.3 var(--title-font-family);
  }
}
