.video-card{
  padding: 16px;
  border-radius: 10px;
  background: #1C1F29;
  font-size: 18px;
  color: var(--white);
  font-weight: 600;
  max-width: 441px;
  width: 100%;
  position: relative;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;

  &-img{
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    height: 208px;
    background: #D9D9D9;
    margin-bottom: 16px;

    @include breakpoint(medium down) {
      height: 180px;
      position: relative;
    }

    @include breakpoint(small down) {
      height: 150px;
    }

    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &-bottom{
    padding-right: 100px;
    min-height: 41px;
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: 600;

    @include breakpoint(medium down) {
      min-height: auto;
    }

    @include breakpoint(small down) {
      font-size: 14px;
      padding-right: 65px;
    }
  }

  &-link{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
    width: 41px;
    position: absolute;
    bottom: 16px;
    right: 16px;

    svg{
      width: 100%;
      height: 100%;
      path{
       transition: all .3s;
      }
      circle{
        transition: all .3s;
      }
    }

    @include breakpoint(medium down) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 44px;
      width: 44px;

    }
  }
  &-link:hover, &-link:visited{
    svg{
      path{
        fill: #DCCEFE;
      }
      circle:nth-child(2){
        stroke: #DCCEFE;
      }
    }
    @include breakpoint(medium down) {
      svg{
        path{
          fill: #FF4F32;
        }
        circle:nth-child(2){
          stroke: #FF4F32;
        }
      }
    }
  }
}