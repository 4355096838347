.rate {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;

  &-stars {
    height: 16px;
    aspect-ratio: calc(16 * 5) / 16;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath fill='%23FFE5B3' d='M4.256 13.483c-.762.525-1.766-.178-1.535-1.073l.72-2.794a1 1 0 0 0-.322-1.012L.923 6.742c-.691-.586-.31-1.715.594-1.762l2.72-.142a1 1 0 0 0 .888-.658l.935-2.582c.318-.88 1.562-.88 1.88 0l.935 2.582a1 1 0 0 0 .888.658l2.715.142c.905.047 1.285 1.177.593 1.762l-2.194 1.856a1 1 0 0 0-.323 1.013l.721 2.798c.23.895-.772 1.597-1.534 1.074l-2.18-1.497a1 1 0 0 0-1.133.001l-2.172 1.496Z'/%3E%3C/svg%3E")
      repeat-x top left / contain;
    position: relative;

    > span {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath fill='%23FFA800' d='M4.256 13.483c-.762.525-1.766-.178-1.535-1.073l.72-2.794a1 1 0 0 0-.322-1.012L.923 6.742c-.691-.586-.31-1.715.594-1.762l2.72-.142a1 1 0 0 0 .888-.658l.935-2.582c.318-.88 1.562-.88 1.88 0l.935 2.582a1 1 0 0 0 .888.658l2.715.142c.905.047 1.285 1.177.593 1.762l-2.194 1.856a1 1 0 0 0-.323 1.013l.721 2.798c.23.895-.772 1.597-1.534 1.074l-2.18-1.497a1 1 0 0 0-1.133.001l-2.172 1.496Z'/%3E%3C/svg%3E")
        repeat-x top left / contain;
    }
  }
}
