.ans{
  &-count{
    display: grid;
    grid-template-columns: auto 290px;
    justify-content: center;
    gap: 24px;
    align-items: center;
    padding: 32px;
    border-radius: 10px;
    background: var(--primary-light-color);
    box-shadow: 0px 6px 0px 0px #A382FA;
    max-width: 700px;
    margin: 0 auto;

    @include breakpoint(small down) {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px 12px;
    }

    &-img img{
      @include breakpoint(small down) {
        width: 166px;
        height: 92px;
      }
    }

    &-ttl{
      font-size: 24px;
      font-weight: 700;

      @include breakpoint(small down) {
        font-size: 16px;
        font-weight: 800;
      }
    }

    &-box{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 24px;

      @include breakpoint(small down) {
        gap: 16px;
      }
    }

    &-timer{
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 1px solid var(--black);
      background: #FFEB51;
      box-shadow: 0px 4px 0px 0px #000;
      padding: 16px 30px;

      @include breakpoint(small down) {
        padding: 16px 22px;
      }
    }

    &-item{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;
      gap: 6px;

      &:first-child{
        margin-right: 24px;
        padding-right: 24px;

        &:after{
          content: '';
          position: absolute;
          width: 1px;
          background-color: var(--white);
          height: 39px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    span{
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 400;
      opacity: 0.6;

      @include breakpoint(small down) {
        text-transform: none;
      }

      &:first-child{
        font-size: 32px;
        font-weight: 700;
        opacity: 1;

        @include breakpoint(small down) {
          font-size: 26px;
        }
      }
    }
  }

  &-tabs{
    &-btns{
      overflow: auto;
      display: flex;
      padding: 0 2px;

      &::-webkit-scrollbar{
        display: none;
      }
    }

    &-btns-inner{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-wrap: nowrap;
      margin: 0 auto;
    }

    &-btn{
      border-radius: 10px 10px 0px 0px;
      background: var(--primary-light-color);
      text-decoration: none;
      font-size: 16px;
      color: var(--black);
      font-weight: 600;
      text-transform: uppercase;
      display: flex;
      flex-wrap: nowrap;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      padding: 16px 20px;
      transition: all .3s;
      white-space: nowrap;

      @include breakpoint(large down) {
        padding: 12px;
        font-size: 12px;
        font-weight: 700;
        gap: 6px;
      }

      &:hover,&:focus, &.is-active{
        background: var(--primary-lighter-color);
        color: var(--black);
      }

      &.is-done{
        &:after{
          content: "";
          display: block;
          width: 19px;
          height: 19px;
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19' fill='none'%3E%3Cpath d='M2.902 8.79666C2.57408 8.79758 2.25313 8.89132 1.97627 9.06703C1.69941 9.24275 1.47795 9.49327 1.33753 9.78959C1.1971 10.0859 1.14344 10.4159 1.18275 10.7415C1.22207 11.067 1.35275 11.3748 1.55968 11.6292L5.97097 17.033C6.12826 17.2283 6.32987 17.3833 6.55906 17.485C6.78826 17.5867 7.03842 17.6323 7.28877 17.618C7.8242 17.5892 8.30761 17.3028 8.61581 16.8318L17.7792 2.07424C17.7807 2.07179 17.7822 2.06934 17.7839 2.06693C17.8698 1.93492 17.842 1.6733 17.6645 1.50895C17.6157 1.46382 17.5583 1.42915 17.4956 1.40706C17.4329 1.38498 17.3664 1.37596 17.3001 1.38056C17.2339 1.38516 17.1692 1.40327 17.1102 1.43379C17.0512 1.46431 16.9991 1.50659 16.957 1.55802C16.9537 1.56207 16.9504 1.56605 16.9469 1.56997L7.70549 12.0113C7.67032 12.0511 7.62762 12.0834 7.57984 12.1065C7.53207 12.1296 7.48019 12.143 7.4272 12.1458C7.37422 12.1487 7.3212 12.141 7.27122 12.1232C7.22123 12.1054 7.17528 12.0779 7.13604 12.0421L4.069 9.25112C3.75046 8.95912 3.33412 8.79699 2.902 8.79666Z' fill='%23FF4F32'/%3E%3C/svg%3E") no-repeat center center / contain;

          @include breakpoint(large down) {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    &-content{
      background: var(--primary-lighter-color);
      border-radius: 10px;
      padding: 56px 36px 52px 36px;


      @include breakpoint(medium down) {
        padding: 32px 20px;
      }

      @include breakpoint(small down) {
        margin: 0 -20px;
      }
    }
  }

  &-item{
    &-inner{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 56px;
      flex-direction: row;

      @include breakpoint(medium down) {
        flex-direction: column;
      }

      &.ans-grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 32px;
        column-gap: 16px;
        padding-top: 32px;

        @include breakpoint(large down) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include breakpoint(medium down) {
          grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint(small down) {
          grid-template-columns: repeat(1, 100%);
          padding-top: 16px;
        }
      }
    }

    &-txt{
      text-align: center;
      @include breakpoint(medium down) {
        font-size: 12px;
        font-weight: 400;
      }
    }

    &-ttl{
      font-size: 24px;
      font-weight: 700;
      text-align: center;

      @include breakpoint(medium down) {
        font-size: 16px;
        font-weight: 800;
      }
    }

    &-box{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      max-width: 605px;
    }

    &-img{
      border-radius: 10px;
      border: 1px solid var(--primary-color);
      box-shadow: 0px 4px 0px 0px var(--primary-color);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 357px;
      max-width: 100%;
      height: 314px;

      &.full{
        width: max-content;
        height: auto;

        @include breakpoint(small down) {
          width: 100%;
          height: auto;
          max-height: 281px;
        }
      }

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include breakpoint(small down) {
        width: 100%;
        height: auto;
        max-height: 281px;
      }
    }

    &-tabs{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 32px;

      @include breakpoint(small down) {
        padding-top: 16px;
      }

      &-btn{
        text-transform: uppercase;
        text-decoration: none;
        width: 309px;
        max-width: 33%;
        padding: 16px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid var(--black);
        transition: all .2s;
        font-size: 16px;
        font-weight: 600;
        color: var(--black);

        @include breakpoint(small down) {
          font-size: 14px;
          max-width: 107px;
        }

        &:hover{
          color: var(--black);
        }

        &.is-active{
          border-bottom: 5px solid var(--primary-color);
        }
      }
    }

    .shop-card{
      .shop-card-btn{
        display: none;
      }
    }
  }

  .shop-card{
    .shop-card-fav, .shop-card-pur, .shop-card-price, .shop-card-head-meta{
      display: none;
    }

    .shop-card-info{
      background-color: var(--primary-lighter-color);
      top: 10px;

      &.is-active{
        background-color: var(--white);
      }
    }
  }

  &-third{
    h1{
      margin-bottom: 0;
    }

    &-grid{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 16px;
      column-gap: 40px;
      padding-top: 32px;

      @include breakpoint(large down) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include breakpoint(medium down) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include breakpoint(small down) {
        grid-template-columns: repeat(1, 100%);
      }
    }
  }

  &-fifth{
    h1{
      margin-bottom: 0;
    }

    &-content{
      display: grid;
      grid-template-columns: 270px auto;
      gap: 40px;
      padding-top: 32px;

      @include breakpoint(medium down) {
        display: flex;
        flex-direction: column;
      }
    }

    &-grid{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 16px;
      column-gap: 40px;

      @include breakpoint(large down) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include breakpoint(small down) {
        grid-template-columns: repeat(1, 100%);
      }
    }

    .shop-cats {
      grid-area: auto;

      @include breakpoint(medium down) {
          width: 140px;
          min-width: max-content;
      }
    }
  }
}
