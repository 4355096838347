.primary-menu {
  --space: 40px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space);
  font-family: var(--title-font-family);

  > li {
    position: relative;
  }
  a {
    padding: 10px 0;
    color: var(--dark-grey);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 2px;
    transition: color var(--speed-fast);
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;

    &:hover {
      color: var(--primary-color);
      transition: fill var(--speed-fast);
    }
  }
}

.slide-menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 0 0 34px;
  font-family: var(--title-font-family);
  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    color: var(--white);
    text-decoration: none;
    font-weight: 700;
    font-size: 38px;
    margin-bottom: 32px;

    @include breakpoint(small only) {
      font-size: 32px;
    }
  }

  ul {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;

    li:not(:last-child) {
      margin-bottom: 6px;
    }

    a {
      font-size: 18px;
      padding: 0;
      font-weight: 400;
    }
  }
}
