.grade {
  margin-top: 48px;
  @include breakpoint(medium down) {
    margin-top: 73px;
  }
  @include breakpoint(small down) {
    margin-top: 32px;
  }
  &-area {
    max-width: 764px;
    margin: 0 auto;
    text-align: center;
  }
  &-title {
    margin-bottom: 16px;
  }
  &-text {
    padding: 0 51px;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 24px;
    @include breakpoint(medium down) {
      padding: 0 13px;
    }
    @include breakpoint(small down) {
      font-weight: 700;
      padding: 0;
    }
  }
  &-form {
    fieldset {
      background: var(--white);
      box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
      border-radius: var(--radius);
      margin: 0 auto 16px;
      padding: 12px 16px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      gap: 3px;
      width: min-content;
      border: none;
    }
    textarea {
      filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.07));
      background: var(--white);
      border-radius: var(--radius);
      padding: 16px;
      max-width: 481px;
      height: 128px;
      display: block;
      margin: 0 auto 24px;
      border: none;
      &::placeholder {
        color: var(--medium-grey);
      }
    }

    label {
      width: 36px;
      height: 36px;
      cursor: pointer;
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        width: 36px;
        height: 36px;
        background: url("data:image/svg+xml,%3Csvg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='1' d='M8.77541 29.9658C7.25177 31.0151 5.24271 29.611 5.70434 27.8196L7.5301 20.7343C7.7214 19.9919 7.4715 19.2055 6.88676 18.7097L1.34526 14.0113C-0.0360231 12.8402 0.726014 10.5829 2.53449 10.4886L9.44907 10.1279C10.2521 10.086 10.9519 9.56734 11.2255 8.81124L13.6194 2.19659C14.256 0.437384 16.744 0.437378 17.3806 2.19658L19.7745 8.81124C20.0481 9.56734 20.7479 10.086 21.5509 10.1279L28.4554 10.488C30.2652 10.5824 31.0264 12.8425 29.6425 14.0126L24.1029 18.6962C23.5167 19.1918 23.2659 19.9792 23.4575 20.7225L25.2855 27.8168C25.7468 29.607 23.7407 31.011 22.2167 29.9646L16.6208 26.1223C15.9379 25.6535 15.0367 25.6541 14.3544 26.1239L8.77541 29.9658Z' fill='%23FFE6C0'/%3E%3C/svg%3E")
          no-repeat center center / contain;
      }
      &:hover:before {
        background: url("data:image/svg+xml,%3Csvg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='1' d='M8.77541 29.9658C7.25177 31.0151 5.24271 29.611 5.70434 27.8196L7.5301 20.7343C7.7214 19.9919 7.4715 19.2055 6.88676 18.7097L1.34526 14.0113C-0.0360231 12.8402 0.726014 10.5829 2.53449 10.4886L9.44907 10.1279C10.2521 10.086 10.9519 9.56734 11.2255 8.81124L13.6194 2.19659C14.256 0.437384 16.744 0.437378 17.3806 2.19658L19.7745 8.81124C20.0481 9.56734 20.7479 10.086 21.5509 10.1279L28.4554 10.488C30.2652 10.5824 31.0264 12.8425 29.6425 14.0126L24.1029 18.6962C23.5167 19.1918 23.2659 19.9792 23.4575 20.7225L25.2855 27.8168C25.7468 29.607 23.7407 31.011 22.2167 29.9646L16.6208 26.1223C15.9379 25.6535 15.0367 25.6541 14.3544 26.1239L8.77541 29.9658Z' fill='%23FFBC58'/%3E%3C/svg%3E")
          no-repeat center center / contain;
      }
    }
    input {
      display: none;
      &:checked ~ label:before,
      & + label:hover ~ label:before,
      & + label:hover:before {
        background: url("data:image/svg+xml,%3Csvg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='1' d='M8.77541 29.9658C7.25177 31.0151 5.24271 29.611 5.70434 27.8196L7.5301 20.7343C7.7214 19.9919 7.4715 19.2055 6.88676 18.7097L1.34526 14.0113C-0.0360231 12.8402 0.726014 10.5829 2.53449 10.4886L9.44907 10.1279C10.2521 10.086 10.9519 9.56734 11.2255 8.81124L13.6194 2.19659C14.256 0.437384 16.744 0.437378 17.3806 2.19658L19.7745 8.81124C20.0481 9.56734 20.7479 10.086 21.5509 10.1279L28.4554 10.488C30.2652 10.5824 31.0264 12.8425 29.6425 14.0126L24.1029 18.6962C23.5167 19.1918 23.2659 19.9792 23.4575 20.7225L25.2855 27.8168C25.7468 29.607 23.7407 31.011 22.2167 29.9646L16.6208 26.1223C15.9379 25.6535 15.0367 25.6541 14.3544 26.1239L8.77541 29.9658Z' fill='%23FFBC58'/%3E%3C/svg%3E")
          no-repeat center center / contain;
      }
    }
    //input.star-clear + label{
    //  text-indent: -9999px;
    //  width: .5em;
    //  margin-left: -.5em;
    //  &:before{
    //    background: none ;
    //    width: .5em;
    //  }
    //}
  }
  .button {
    padding: 15px 75px;
  }
}
