.lang-sw {
  --space: 8px;
  position: relative;

  &:hover,
  &:focus-within {
    .lang-sw-rest {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-cur {
    display: flex;
    align-items: center;
    padding: 4px 0;
    gap: 4px;
    text-transform: uppercase;
    &::after {
      content: '';
      display: block;
      width: 9px;
      height: 5px;
      background: url("data:image/svg+xml,%3Csvg width='7' height='5' viewBox='0 0 7 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.75671 4.17412L0.502068 1.66896C-0.0771018 1.02544 0.379591 0 1.24536 0H5.75464C6.62041 0 7.0771 1.02544 6.49793 1.66897L4.24329 4.17412C3.84605 4.6155 3.15395 4.6155 2.75671 4.17412Z' fill='%23333333'/%3E%3C/svg%3E")
        no-repeat center center / contain;
    }
  }

  &-rest {
    display: grid;
    gap: var(--space);
    padding: var(--space);
    position: absolute;
    top: 100%;
    right: calc(var(--space) * -1);
    background: var(--white);
    box-shadow: var(--shadow);
    border-radius: var(--radius-small);
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--speed-fast);

    a {
      text-transform: uppercase;
      text-decoration: none;
      display: flex;
      color: var(--dark-grey);
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}
