@keyframes button {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.shop-card {
  display: grid;
  grid-template-rows: auto 1fr;
  position: relative;
  overflow: hidden;
  border-radius: var(--radius);
  text-align: left;

  &:hover {
    .shop-card-btn {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-head {
    position: relative;
    border-bottom: 3px solid var(--black);

    &-meta {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px;
    }
  }

  &-img {
    display: block;
    width: 100%;
    aspect-ratio: 270/235;
    object-fit: cover;
  }

  &-fav {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='23' fill='%23FF4F32'%3E%3Cpath d='M17.482.523c-2.073 0-4.013.886-5.305 2.372C10.884 1.409 8.944.523 6.87.523 3.082.523 0 3.433 0 7.012c0 2.802 1.769 6.042 5.258 9.632 2.684 2.762 5.605 4.901 6.436 5.49l.482.343.483-.342c.83-.59 3.751-2.729 6.436-5.491 3.489-3.59 5.258-6.83 5.258-9.632 0-3.578-3.082-6.49-6.87-6.49Zm.43 15.095c-2.208 2.272-4.584 4.11-5.736 4.956-1.152-.847-3.527-2.684-5.736-4.956-3.162-3.254-4.834-6.23-4.834-8.606 0-2.742 2.362-4.973 5.265-4.973 1.91 0 3.674.984 4.603 2.567l.703 1.196.702-1.196c.929-1.583 2.693-2.567 4.603-2.567 2.903 0 5.265 2.231 5.265 4.973 0 2.376-1.672 5.352-4.835 8.606Z'/%3E%3C/svg%3E")
      no-repeat center var(--white);
    width: 36px;
    height: 34px;
    border-radius: var(--radius-small);
    position: absolute;
    top: 10px;
    right: 10px;

    &.is-active {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='23' fill='%23FF4F32'%3E%3Cpath fill-rule='evenodd' d='M12.177 3.326C13.469 1.84 15.409.954 17.482.954c1.155 0 2.245.27 3.201.748 2.172.993 3.67 3.094 3.67 5.526V7.443c0 2.802-1.769 6.043-5.258 9.632-2.685 2.762-5.605 4.901-6.436 5.491l-.483.342-.482-.342c-.831-.59-3.752-2.729-6.436-5.491C1.768 13.485 0 10.245 0 7.443v-.215c0-2.43 1.497-4.53 3.667-5.524A7.152 7.152 0 0 1 6.87.954c2.073 0 4.013.887 5.306 2.372Z' clip-rule='evenodd'/%3E%3C/svg%3E")
        no-repeat center var(--white);
    }
  }

  &-rate {
    background: var(--white);
    border-radius: var(--radius-small);
    padding: 4px 8px;
    margin-left: auto;
  }

  &-foot {
    background: var(--primary-color);
    color: var(--white);
    position: relative;
    overflow: hidden;
    padding: 10px 10px 20px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 16px 12px;
  }

  &-ttl {
    margin: 0;
    font: 700 16px/20px var(--body-font-family);
  }

  &-price {
    width: 93px;
    height: 53px;
    background: url('../img/price-bg.svg') no-repeat center / contain;
    display: flex;
    align-items: center;
    justify-content: center;

    &-val {
      display: grid;
      align-items: center;
      grid-template-columns: auto 1fr;
      gap: 3px;
      color: var(--black);
      font: 800 20px/24px var(--title-font-family);
      transform: translateY(1px) rotate(-12deg);

      img {
        margin-top: -2px;
      }
    }
  }

  &-pur {
    grid-column: span 2;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 5px;
    font-size: 12px;

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;

      li {
        display: flex;

        &:not(:first-child) {
          margin-left: -6px;
        }

        img {
          border-radius: 50%;
          border: 1px solid var(--white);
        }
      }
    }
  }

  &-btn {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--yellow);
    font: 700 40px/1 var(--title-font-family);
    text-transform: uppercase;
    -webkit-text-stroke: 1px black;
    text-shadow: 2px 2px 0 var(--black);
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--speed-fast);

    span {
      padding: 0 15px;
      position: relative;
      animation: button linear 2s infinite;
    }
  }

  &-info {
    background: url("data:image/svg+xml,%3Csvg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.0156 14.6094H17.2656V26.0312' stroke='%23333333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.418 26.2969H21.582' stroke='%23333333' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M17 10.625C16.5797 10.625 16.1689 10.5004 15.8194 10.2669C15.47 10.0334 15.1976 9.7015 15.0368 9.3132C14.8759 8.92491 14.8338 8.49764 14.9158 8.08543C14.9978 7.67322 15.2002 7.29459 15.4974 6.9974C15.7946 6.70021 16.1732 6.49783 16.5854 6.41583C16.9976 6.33384 17.4249 6.37592 17.8132 6.53676C18.2015 6.69759 18.5334 6.96996 18.7669 7.31941C19.0004 7.66887 19.125 8.07972 19.125 8.5C19.125 9.06359 18.9011 9.60409 18.5026 10.0026C18.1041 10.4011 17.5636 10.625 17 10.625Z' fill='%23333333'/%3E%3C/svg%3E")
      no-repeat center var(--white);
    width: 36px;
    height: 34px;
    border-radius: var(--radius-small);
    position: absolute;
    top: 52px;
    right: 10px;
    z-index: 11;
    transition: top 0.1s;

    &.is-active {
      top: 10px;
      background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99999 9.51666L2.69166 14.825C2.49305 15.0236 2.24027 15.1229 1.93333 15.1229C1.62638 15.1229 1.3736 15.0236 1.17499 14.825C0.97638 14.6264 0.877075 14.3736 0.877075 14.0667C0.877075 13.7597 0.97638 13.5069 1.17499 13.3083L6.48333 7.99999L1.17499 2.69166C0.97638 2.49305 0.877075 2.24027 0.877075 1.93333C0.877075 1.62638 0.97638 1.3736 1.17499 1.17499C1.3736 0.97638 1.62638 0.877075 1.93333 0.877075C2.24027 0.877075 2.49305 0.97638 2.69166 1.17499L7.99999 6.48333L13.3083 1.17499C13.5069 0.97638 13.7597 0.877075 14.0667 0.877075C14.3736 0.877075 14.6264 0.97638 14.825 1.17499C15.0236 1.3736 15.1229 1.62638 15.1229 1.93333C15.1229 2.24027 15.0236 2.49305 14.825 2.69166L9.51666 7.99999L14.825 13.3083C15.0236 13.5069 15.1229 13.7597 15.1229 14.0667C15.1229 14.3736 15.0236 14.6264 14.825 14.825C14.6264 15.0236 14.3736 15.1229 14.0667 15.1229C13.7597 15.1229 13.5069 15.0236 13.3083 14.825L7.99999 9.51666Z' fill='%23333333'/%3E%3C/svg%3E")
        no-repeat center var(--white);
    }
  }

  &-info-box {
    position: absolute;
    padding: 16px 50px 16px 16px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--primary-lighter-color);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.7s;

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 14px;
      font-weight: 400;
      padding: 0;
      margin: 0;

      span {
        font-weight: 600;
      }

      li {
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }

    &.is-active {
      opacity: 1;
      z-index: 10;
    }
  }
}
