.radio-buttons {
  --color: var(--primary-color);
  --color-dark: var(--primary-dark-color);
  display: flex;
  margin: auto;

  &.secondary {
    --color: var(--secondary-color);
    --color-dark: var(--secondary-dark-color);
  }

  input[type='radio'] {
    display: none;

    + label {
      cursor: pointer;
      font: 600 10px/12px var(--body-font-family);
      border: 1px solid var(--color);
      padding: 12px;
      color: var(--color);

      @include breakpoint(medium) {
        font: 600 14px/17px var(--body-font-family);
      }

      &:hover {
        color: var(--color-dark);
      }

      &:first-of-type {
        border-radius: var(--radius-small) 0 0 var(--radius-small);
      }

      &:last-of-type {
        border-radius: 0 var(--radius-small) var(--radius-small) 0;
      }
    }

    &:checked {
      + label {
        background: var(--color);
        color: var(--white);
        box-shadow: var(--inner-sharow);
      }
    }
  }
  &.large {
    margin-bottom: 16px;
    input[type='radio'] {
      + label {
        padding: 12px 14px;
        font-weight: 400;
        @include breakpoint(small down) {
          font-size: 14px;
          text-align: center;
        }
      }
    }
    @include breakpoint(small down) {
      margin-bottom: 8px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  &.white{
    --color: var(--white);
    --color-dark: var(--white);

    input[type=radio]{
      &:checked{
        +label{
          color: var(--black);
          box-shadow: none;
        }
      }
    }
  }
}
