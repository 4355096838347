.dash-hero{
  position: relative;
  height: 600px;
  overflow: hidden;
  transition: opacity .1s;

  @include breakpoint(medium down) {
    height: 470px;
    margin-bottom: 0;
  }

  @include breakpoint(small down) {
    height: 385px;
  }

  &-bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #10160E 100%), no-repeat;
    z-index: 2;
  }

  &-content{
    position: absolute;
    left: 50%;
    bottom: 0;
    color: #fff;
    text-align: center;
    transform: translate(-50%, 0);
    z-index: 3;
    font-size: 24px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 60px 58px 60px;
    max-width: 100%;

    @include breakpoint(medium down) {
      padding-bottom: 40px;
    }

    @include breakpoint(small down) {
      font-size: 14px;
      padding: 0 45px 20px 45px;
    }

    div{
      width: 640px;
      padding-top: 24px;

      @include breakpoint(medium down) {
        width: max-content;
        max-width: 100%;
        padding-top: 10px;
      }
    }
  }

  img{
    position: absolute;
    z-index: 1;
    width: 100%;
    object-fit: cover;
    bottom: -20px;
    min-height: calc(100% + 20px);

    @media (min-width: 1441px) {
      bottom: -110px;
    }

    @include breakpoint(medium down) {
      width: max-content;
      max-width: 100%;
    }
  }
}

.dash-ttl{
  font-size: 62px;
  font-weight: 900;
  line-height: 1;
  max-width: 500px;
  margin-bottom: 0;

  @include breakpoint(medium down) {
    font-size: 52px;
    max-width: 100%;
    width: max-content;
  }

  @include breakpoint(small down) {
    font-size: 38px;
  }
}

.dash-head{
  border-radius: 20px;
  background: linear-gradient(180deg, #B599FF 0%, #8560E6 100%);
  color: var(--white);
  padding: 32px 16px;

  &-txt{
    font-size: 24px;
    font-weight: 700;
    padding-top: 13px;

    @include breakpoint(small down) {
      font-size: 16px;
    }
  }

  @include breakpoint(medium down) {
    text-align: center;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.dash-title{
  font-size: 40px;
  font-weight: 700;

  @include breakpoint(medium down) {
    font-size: 36px;
  }

  @include breakpoint(small down) {
    font-size: 26px;
  }

  span{
    position: relative;
    color: #503C82;
    display: inline-block;
    padding: 0 5px;

    &:before{
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 11px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='225' height='11' viewBox='0 0 225 11' fill='none'%3E%3Cpath opacity='0.5' d='M0.390625 6.02538C91.5093 10.0977 146.018 -0.089211 224.391 6.02538' stroke='%23683FD2' stroke-width='7' stroke-miterlimit='10'/%3E%3C/svg%3E") no-repeat center center / cover;
      bottom: 1px;
      left: 0;

      @include breakpoint(medium down) {
        height: 9px;
      }

      @include breakpoint(small down) {
        height: 7px;
      }
    }
  }

  &.dash-title-circle{
    color: #503C82;
    position: relative;
    text-transform: uppercase;
    line-height: 1.8;

    &:after{
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='211' height='72' viewBox='0 0 211 72' fill='none'%3E%3Cpath opacity='0.4' d='M110.793 4.9084C60.2119 0.951309 14.4096 20.2494 5.31234 39.4653C-3.78494 58.6812 29.2843 68.2192 77.7917 67.6024C126.299 66.9855 197.008 62.349 206.243 38.5092C215.478 14.6693 122.327 13.5415 98.2165 16.2088' stroke='%23683FD2' stroke-width='7' stroke-miterlimit='10'/%3E%3C/svg%3E") no-repeat center center / contain;
    }
  }
}

.dash-page main{
  a:not([class]){
    color: #2062C5;
    text-decoration: underline;
    font-weight: 700;

    &:not([href]){
      color: var(--black);
      text-decoration: none;
    }
  }
  .section{
    @include breakpoint(large up) {
      margin-bottom: 86px;
    }
  }
}

.dash-page{
  .sup-btn{
    display: none;
  }
}