$zks-crds: (
  1: 25.5%,
  2: 11%,
  3: 25.5%,
  4: 13.5%,
  5: 23%,
  6: 17%,
  7: 10%,
  8: 19%,
  9: 22%
);

.card-font-1 {
  font-family: 'Lobster', var(--body-font-family);
}

.card-font-2 {
  font-family: 'Caveat', var(--body-font-family);
}

.card-font-3 {
  font-family: 'Amatic SC', var(--body-font-family);
}

.card-create {
  @include breakpoint(large) {
    max-width: 866px;
  }

  &-area {
    background: var(--primary-dark-color);
    padding: 32px 0 42px;
    overflow: hidden;

    @include breakpoint(medium) {
      padding: 42px 0 108px;
    }

    @include breakpoint(large) {
      padding: 42px 0 134px;
    }
  }

  &-head {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;

    @include breakpoint(medium) {
      gap: 24px;
      margin-bottom: 40px;
    }
  }

  &-ttl {
    flex: 1;
    margin: 0;
    text-align: center;
    color: var(--white);
  }

  &-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 24px;
    position: relative;
    z-index: 2;

    @include breakpoint(medium) {
      margin-bottom: 50px;
    }
  }

  &-nav-btn {
    svg {
      fill: var(--white);

      @include breakpoint(medium) {
        display: none;
      }
    }

    @include breakpoint(small down) {
      padding: 12px;

      span {
        display: none;
      }
    }

    &[disabled] {
      background: var(--primary-color);

      opacity: 0;
      pointer-events: none;
    }
  }

  &-steps {
    display: flex;
    padding: 10px 18px;
    background: var(--white);
    border-radius: var(--radius);
    margin: 0 auto 24px;
    position: relative;
    z-index: 3;

    @include breakpoint(medium) {
      padding: 24px;
      margin-bottom: 40px;
    }

    &-itm {
      flex: 1 0 auto;
      padding: 0 10px 12px 0;
      position: relative;
      border-bottom: 1px dashed var(--secondary-color);

      &.is-current {
        @include breakpoint(small down) {
          border-bottom: 1px solid var(--secondary-color);
        }

        @include breakpoint(medium) {
          border-top: 1px solid var(--secondary-color);
        }
      }

      &:not(.is-active) {
        :is(.card-create-steps-itm-ttl, .card-create-steps-itm-txt) {
          opacity: 0.5;
        }
      }

      &:after {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        background: var(--secondary-color);
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translate(50%, 50%);
      }

      &:first-child {
        &:before {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          background: var(--secondary-color);
          border-radius: 50%;
          position: absolute;
          left: 0;
          bottom: 0;
          transform: translate(-50%, 50%);

          @include breakpoint(medium) {
            top: 0;
            left: 0;
            transform: translate(-50%, -50%);
          }
        }
      }

      @include breakpoint(medium) {
        padding: 20px 0 0 0;
        border-bottom: 0;
        border-top: 1px dashed var(--secondary-color);

        &:after {
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
        }
      }

      &-ttl {
        font: 800 16px/19px var(--body-font-family);
        color: var(--secondary-color);

        @include breakpoint(medium) {
          font: 800 24px/29px var(--body-font-family);
        }
      }

      &-txt {
        font: 600 18px/25px var(--body-font-family);
        margin-top: 6px;

        @include breakpoint(small down) {
          display: none;
        }
      }
    }
  }

  &-slider {
    overflow: visible !important;
    z-index: 9;

    >.swiper-wrapper>.swiper-slide {
      transition: all var(--speed);
      opacity: 0;
      z-index: 1;

      &.swiper-slide-active {
        z-index: 3;
        opacity: 1;
      }
    }
  }

  &-select {
    &-head {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      gap: 24px;
      grid-template-areas: 'empty ttl nav';
      align-items: center;
      margin-bottom: 24px;
      position: relative;
      z-index: 9;

      @include breakpoint(medium) {
        margin-bottom: 32px;
      }

      &.hide-on-medium {
        @include breakpoint(medium) {
          display: none;
        }
      }
    }

    &-ttl {
      grid-area: ttl;
      color: var(--white);
      font: 700 16px/20px var(--body-font-family);

      @include breakpoint(medium) {
        display: none;
      }
    }
  }

  &-select-slider {
    max-width: 576px;
    margin: 0 auto;
    overflow: visible !important;
    z-index: 9;

    .swiper-wrapper {
      overflow: visible !important;
    }

    .swiper-slide {
      transition: all var(--speed);

      &:not(.swiper-slide-active) {
        filter: brightness(0.3);
      }
    }

    &-nav {
      display: flex;
      gap: 12px;
      position: relative;
      z-index: 3;
      grid-area: nav;
      margin-left: auto;

      @include breakpoint(medium) {
        gap: 24px;
      }
    }

    &-btn {
      display: flex;
      transition: opacity var(--speed-fast);

      &[disabled] {
        opacity: 0.4;
        cursor: not-allowed;
      }

      svg {
        fill: var(--white);
      }
    }
  }

  &-text {
    max-width: 576px;
    margin: 0 auto;
    position: relative;
    aspect-ratio: 576/412;

    @each $i, $s in $zks-crds {
      &.style-#{$i} {
        .card-create-text-area {
          padding-right: $s;
        }
      }
    }

    &-area {
      --font-size: 26px;
      display: block;
      padding: 0;
      width: unset;
      border: 0;
      border-radius: 0;
      position: absolute;
      top: 25.7%;
      left: 7.5%;
      right: 15%;
      bottom: 7.5%;
      background: none;
      font-size: var(--font-size);
      line-height: 1;
      overflow: hidden;
      margin: 0;

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: #b8c1cc;
      }
    }

    &-fonts {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;
      color: var(--white);
      font-size: 26px;
      margin-bottom: 26px;

      [type='radio'] {
        display: none;

        +label {
          opacity: 0.5;
          cursor: pointer;
        }

        &:checked {
          +label {
            opacity: 1;
          }
        }
      }
    }
  }

  &-choose {
    display: flex;
    align-items: center;
    gap: 40px;

    @include breakpoint(large) {
      gap: 100px;
    }

    >* {
      flex: 1;
    }

    &-img {
      @include breakpoint(small down) {
        display: none;
      }
    }

    &-act {
      display: flex;
      flex-direction: column;
      gap: 6px;
      color: var(--white);

      &-lbl {
        span.error {
          transform: translate(-50%, 0);
        }
      }

      .button {
        display: flex;
        margin: 12px auto 0;

        @include breakpoint(small down) {
          width: 100%;
        }

        @include breakpoint(medium) {
          min-width: 80%;
        }
      }
    }
  }
}

.zks-crd {
  position: relative;

  &-img {
    position: absolute;
    top: 30%;
    right: 0;
    width: 36%;
  }

  @each $i, $s in $zks-crds {
    &.style-#{$i} {
      .zks-crd-txt {
        padding-right: $s;
      }
    }
  }
}

.zks-cards-grid {
  display: grid;
  gap: 16px;

  @include breakpoint(medium only) {
    max-width: 576px;
    margin: 0 auto;
  }

  @include breakpoint(large) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.zks-card {
  text-align: left;
  overflow: hidden;
  border-radius: var(--radius);
  box-shadow: 0 0 0 1px var(--secondary-color);
  background: var(--white);
  padding-bottom: 24px;

  &-txt {
    --font-size: 26px;
    position: relative;
    aspect-ratio: 576/412;
    pointer-events: none;
    margin-bottom: 25px;

    &-val {
      padding: 0;
      position: absolute;
      top: 25.7%;
      left: 7.5%;
      right: 15%;
      bottom: 11.5%;
      background: none;
      font-size: var(--font-size);
      line-height: 1;
      overflow: hidden;
    }
  }

  &-meta {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
    text-align: center;
    position: relative;
    padding: 0 16px;
    font-size: 14px;
    line-height: 17px;
  }

  &-from {
    font-weight: 600;
  }

  &-photo {
    display: block;
    box-shadow: 0 0 5px 2px rgba(46, 41, 41, 0.17);
    border-radius: 6px;
    margin: -20px 0 10px;
  }

  &-btn {
    padding: 0 16px;
    width: 250px;
    max-width: 100%;
    margin: 16px auto 0;
  }

  &-rev {
    @extend .zks-card-btn;

    &-txt {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: 1px solid var(--secondary-color);
      padding: 10px 12px 16px 6px;
      font-size: 16px;
      line-height: 24px;
      border-radius: var(--radius);
      z-index: 1;
      background: none;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -4px;
        left: 4px;
        width: 100%;
        height: 100%;
        background: var(--secondary-light-color);
        border-radius: var(--radius);
        z-index: -1;
      }

      &:after {
        content: '';
        display: block;
        width: 18px;
        height: 22px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='22' fill='none'%3E%3Cpath fill='%23D7E6FF' fill-rule='evenodd' d='M1.613 0H0v19.012l.076-.132 16.694 2.237c1.03.138 1.598-1.236.785-1.882-2.887-2.294-7.096-5.862-9.669-9.055C5.524 7.25 2.962 2.676 1.613.147V0Z' clip-rule='evenodd'/%3E%3C/svg%3E") no-repeat top left / contain;
        position: absolute;
        left: 100%;
        bottom: 7px;
      }
    }
  }
}

.card-send-reveal {
  display: grid;
  gap: 16px;
  text-align: center;
  background: var(--surface);

  &-ttl {
    text-transform: uppercase;
  }

  .h2 {
    margin: 0;
  }
}