.off-canvas {
  background: rgba(51, 51, 51, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  z-index: 20;
  padding: 0 58px 0 0;
  transition: transform var(--speed);
  @include breakpoint(small only) {
    padding-right: 40px;
  }
  &:not(.is-open) {
    transform: translateX(-100%);
  }
  &-toggle {
    display: flex;
    justify-content: flex-end;
  }
  &-cont {
    background: var(--dark-grey);
    min-height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .lang-sw {
    color: var(--white);
    font-size: 24px;
    padding: 0 0 0 34px;
    @include breakpoint(small only) {
      font-size: 20px;
    }
    &-cur {
      &:after {
        background: url("data:image/svg+xml,%3Csvg width='7' height='5' viewBox='0 0 7 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.75671 4.17412L0.502068 1.66896C-0.0771018 1.02544 0.379591 0 1.24536 0H5.75464C6.62041 0 7.0771 1.02544 6.49793 1.66897L4.24329 4.17412C3.84605 4.6155 3.15395 4.6155 2.75671 4.17412Z' fill='white'/%3E%3C/svg%3E")
          no-repeat center center / contain;
      }
    }
    &-rest {
      top: 100%;
      left: 25px;
      background: inherit;
      box-shadow: none;
      a {
        color: var(--white);
      }
    }
  }
  .bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 24px;
    padding-bottom: 56px;
    text-align: center;
    font-size: 28px;
    color: var(--white);
    font-weight: 700;
    text-transform: uppercase;
    @include breakpoint(small only) {
      font-size: 26px;
      margin-top: 50px;
    }
    &-title {
      font-family: var(--title-font-family);
    }
    &-link {
      font-size: 20px;
      text-transform: none;
      text-decoration: none;
      color: var(--yellow);
    }
  }
}
