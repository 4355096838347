.crd-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 32px;

  &-ttl {
    margin-bottom: 4px;
  }

  &-txt {
    font: 700 16px/20px var(--title-font-family);

    @include breakpoint(medium) {
      font: 700 24px/29px var(--title-font-family);
    }
  }

  &-btn {
    margin-top: 16px;
  }
}

.crd-area {
  display: grid;
  gap: 34px;
  background: var(--white);
  border-radius: var(--radius);
  padding: 24px 12px;
  box-shadow: var(--shadow);

  @include breakpoint(large) {
    padding: 24px;
  }

  &-wrap {
    margin-bottom: 46px;
  }

  &-btn {
    display: flex;
    justify-content: center;
    margin-top: 34px;

    .button {
      min-width: 233px;
    }
  }

  :is(.tabs, .tabs-panel) {
    margin: 0 !important;
  }
}
