.error404 {
  margin-top: 43px;
  &-area {
    text-align: center;
  }
  &-title img {
    margin-bottom: 10px;
    width: 194px;
    height: auto;
    max-width: 100%;
    @include breakpoint(medium down) {
      width: 162px;
    }
    @include breakpoint(small down) {
      width: 108px;
    }
  }
  &-text img {
    width: 550px;
    height: auto;
    margin-bottom: 32px;
    @include breakpoint(medium) {
      width: 460px;
    }
    @include breakpoint(small) {
      width: 308px;
    }
  }
  &-img img {
    width: 590px;
    height: auto;
    margin-bottom: 32px;
    max-width: 100%;
    @include breakpoint(medium down) {
      height: 328px;
    }
    @include breakpoint(small down) {
      height: 290px;
    }
  }
  &-btn {
    .button {
      padding: 16px 88px;
      @include breakpoint(small down) {
        padding: 16px 67px;
      }
    }
  }
}
