.anniversary {
  &-box {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    text-align: center;
  }
  &-text {
    h1 {
      color: var(--primary-dark-color);
      margin-bottom: 16px;
    }
    div {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      max-width: 530px;
      margin: 0 auto;
      @include breakpoint(medium down) {
        font-size: 20px;
      }
      @include breakpoint(small down) {
        font-size: 14px;
      }
    }
  }
}
