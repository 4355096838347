.tippy-box[data-theme~='custom'] {
  font-family: var(--body-font-family);
  background-color: #fff;
  color: var(--black);
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.11);
  border-radius: 12px;
  width: 344px;
  @include breakpoint(small down) {
    width: 300px;
  }
  ul {
    list-style: none;
    padding: 7px 11px;
    margin: 0;
  }
  li {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 0;
    font-size: 14px;
    &:not(:last-child) {
      border-bottom: 1px solid var(--primary-lighter-color);
    }
  }
  img {
    width: 23px;
    height: 23px;
  }
  a {
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
    padding: 0;
    display: inline-block;
    color: var(--black);
    text-decoration: none;
    &.users-link {
      color: var(--secondary-color);
    }
    &:hover {
      color: var(--primary-color);
    }
  }
  .users-name {
    font-weight: 600;
  }
}
.tippy-box[data-theme~='custom'][data-placement^='bottom'] > .tippy-arrow {
  left: auto;
  right: 0;
}
.tippy-box[data-theme~='custom'][data-placement^='bottom']
  > .tippy-arrow::before {
  border-bottom-color: var(--white);
}

.tippy-box[data-theme~='user'] {
  font-family: var(--body-font-family);
  background-color: #fff;
  color: var(--black);
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.11);
  border-radius: 12px;
  width: 185px;
  padding: 18px 12px 6px 18px;
  //@include breakpoint(small down) {
  //  width: 300px;
  //}
  .tippy-content {
    padding: 0;
  }
  .user-ttp-title {
    a {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    padding-bottom: 8px;
    border-bottom: 1px solid var(--primary-lighter-color);
    img {
      width: 16px;
      height: 16px;
    }
  }
  button {
  }
  ul {
    list-style: none;
    padding: 8px 0;
    margin: 0;
    border-bottom: 1px solid var(--primary-lighter-color);
  }
  li {
    display: flex;
    align-items: center;
    gap: 4px;
    &:not(:last-child) {
      padding-bottom: 6px;
    }
  }
  img {
    width: 16px;
    height: 16px;
  }
  a {
    font-size: 16px;
    text-transform: none;
    padding: 0;
    display: inline-block;
    color: var(--black);
    text-decoration: none;
    &:hover {
      color: var(--primary-color);
    }
  }
  button {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    margin-top: 8px;
    &:hover {
      color: var(--primary-color);
    }
  }
}

.tippy-box[data-theme~='user'][data-placement^='bottom']
  > .tippy-arrow::before {
  border-bottom-color: var(--white);
}

.tippy-box[data-theme~='grey'] {
  font-size: 16px;
  font-weight: 700;
  color: var(--white);
  border-radius: var(--radius);
  text-align: center;
  .tippy-content {
    padding: 11px 9px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    color: var(--light-grey);
  }
}
.tippy-box[data-theme~='grey-desc'] {
  border-radius: 12px;
  .tippy-content {
    padding: 12px 18px;
    display: flex;
    gap: 5px;
  }
  .description-info {
    position: relative;
    padding-bottom: 10px;
    text-transform: none;
    div:first-child {
      font-size: 16px;
      font-weight: 700;
    }
    div:nth-child(2) {
      font-size: 14px;
      font-weight: 400;
      color: var(--light-grey);
    }
  }
  .socials {
    position: absolute;
    bottom: 0;
    left: -31px;
  }
}
