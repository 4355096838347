.dash-mission{
  &-flex{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @include breakpoint(medium down) {
      gap: 40px;
    }

    @include breakpoint(small down) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &-inner{
    width: 100%;
    max-width: 994px;
    background: #fff;
    border-radius: 20px;
    padding: 48px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.07);

    @include breakpoint(medium down) {
      padding: 38px 20px;
    }

    @include breakpoint(small down) {
      padding: 24px 20px;
      border-radius: 0;
    }
  }
}