.dash-count{
  @include breakpoint(medium down) {
    background: #1C1F29;
  }

  &-inner{
    padding: 58px 0;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 20px;
    background: #1C1F29;
    color: var(--white);

    @include breakpoint(small down) {
      padding: 42px 0;
    }
  }

  &-ttl{
    font-size: 40px;
    margin-bottom: 32px;
    padding: 0 15px;
    font-weight: 700;

    @include breakpoint(medium down) {
      font-size: 36px;
    }

    @include breakpoint(small down) {
      font-size: 38px;
      font-weight: 900;
    }
  }

  &-top{
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: center;
    gap: 84px;
    margin: 0 auto 24px auto;
    padding: 0 20px;
    max-width: 925px;

    @include breakpoint(small down) {
      gap: 51px;
    }

    @include breakpoint(small down) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
    }
  }

  &-map{
    overflow: auto;
    text-align: center;

    &::-webkit-scrollbar{
      display: none;
    }

    img{
      width: 985px;
      min-width: 985px;
      height: 554px;
      margin: 0 auto;
      display: block;
    }
  }

  &-card{
    text-align: center;
    max-width: 320px;

    &-val{
      font-family: var(--title-font-family);
      color: #FFEB51;
      font-size: 62px;
      line-height: 69px;
      font-weight: 900;

      @include breakpoint(medium down) {
        font-size: 52px;
      }

      @include breakpoint(small down) {
        font-size: 38px;
      }
    }

    &-txt{
      font-size: 18px;
      font-weight: 600;

      @include breakpoint(small down) {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}