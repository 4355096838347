.orders-page {
  margin-top: 32px;
  @include breakpoint(medium down) {
    margin-top: 24px;
  }
  &-area {
    background: var(--white);
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
    border-radius: var(--radius);
    padding: 40px;
    max-width: 1074px;
    margin: 0 auto 33px;
    @include breakpoint(medium down) {
      padding: 24px 40px;
      margin-bottom: 24px;
    }
    @include breakpoint(small down) {
      padding: 24px 15px;
    }
  }
  .tabs {
    &-panel {
      margin: 0;
    }
    .tabs-ttl {
      text-transform: none;
      font-size: 14px;
      font-weight: 400;
      padding: 12px 14px;
      @include breakpoint(small down) {
        text-align: center;
      }
    }
  }
  &-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &-item {
    display: flex;
    align-items: center;
    gap: 25px;
    padding: 16px 0 8px 0;
    border-bottom: 1px solid var(--primary-lighter-color);
    @include breakpoint(small down) {
      font-size: 14px;
    }
    &:last-child {
      border: none;
    }
    .date {
      color: var(--medium-grey);
    }
    .value {
      font-size: 24px;
      margin-left: auto;
      font-weight: 700;
      @include breakpoint(medium down) {
        font-size: 20px;
      }
      @include breakpoint(small down) {
        font-size: 16px;
      }
    }
  }
}
